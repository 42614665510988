import Footer from "@urbanpiper-engineering/meraki-components/dist/Cells/Footer";
import FooterWebProps from "@urbanpiper-engineering/meraki-components/dist/types/src/Components/Cells/Footer/Web/web.interface";
import React from "react";
import screenHelper from "../../external/helpers/screenHelper";
import { useRouter } from "next/router";

function FooterWrapper(props: FooterWebProps) {
  const router = useRouter();
  const isCheckoutScreen = screenHelper.isCheckoutPage(router.pathname);

  if (isCheckoutScreen) {
    return null;
  }

  return (
    <Footer
      contactConfig={props.contactConfig}
      copyRight={props.copyRight}
      moreConfig={props.moreConfig}
      socialConfig={props.socialConfig}
      version={props.version}
      showUrbanPiperBranding={props.showUrbanPiperBranding}
      variant={`default`}
    />
  );
}

export default FooterWrapper;
